'use client';
import React, { useState, useEffect }                       from 'react'
import { useRouter }                                        from 'next/navigation';
//para asignar los datos del usuario a un contexto
import { useSession, getSession }                           from 'next-auth/react';
import { useContext }                                       from 'react';
import { Context_User }                                     from '@/context/Context_User_Provider';

import { cloneDeep }                                        from 'lodash-es';



import Box                                                  from '@mui/material/Box';
import Typography                                           from '@mui/material/Typography';

import FormController                                       from '@/components/FormController/FormController';
import {FormController_Change_FormSchema_Field_Property}    from '@/components/FormController/FormController_functions';
import {iFormController_schema, iUser, 
        }                                                   from '@/libs/interfaces'; 
import { FC_schema_Login }                                  from '@/components/FormController/FormController_schemas';

import mui_theme                                            from '@/styles/mui_theme';
import { isDevelopmentDebug }                               from '@/libs/general';
import { Copy_from_authSession_to_ctxUser }                 from '@/libs/auth';



export default function Login() {
    const { data: session, status } = useSession();
    const { ctx_User, sctx_User } = useContext(Context_User);

    let new_FC_schema_Login = cloneDeep(FC_schema_Login);
    if (isDevelopmentDebug()) { //no hace falta por ahora ya que la password es de tipo "password" asi que no queda expuesta
                                //quizas mas adelante cuando se muetre información interna sensible si que hará falta
                                //pero es tan obvia que la oculto por ahora, ya que se lo he pasado a Gabi Ruiz (Gabi, de castro)
        let user_email, user_password;
        //user_email = "admpcdc";   user_password = "admpcdc";
        //user_email = "demo1";   user_password = "demo2";
        //new_FC_schema_Login = FormController_Change_FormSchema_Field_Property(new_FC_schema_Login, "email", user_email);
        //new_FC_schema_Login = FormController_Change_FormSchema_Field_Property(new_FC_schema_Login, "password", user_password);
        }

    const [stt_formLogin, sstt_formLogin] = useState<iFormController_schema>(new_FC_schema_Login);

    const router = useRouter(); //da error al habilitarlo, no se por qué

    const on_Login_Success = async (pResponse: any) => {
        //console.log ("dentro de... on_Login_Success")
        //actualizo el ctx_User con lo obtenido
        //hago lo necesario para que la inf obtenida con la sesión se refleje en el contexto
        //tb lo pongo en el layout para posibles refrescos manuales
        await Copy_from_authSession_to_ctxUser(ctx_User, sctx_User);

        router.push('/dashboard');//según chatgpt hay que ponerlo despues para dar tiempo a que se cargue la sessión
        }


    /* //ubico todo esto donde deba
    


    */



    return (
        <main className = "login">
            <Box
                sx={{...mui_theme.customized.MuiBox.full_and_center,}}
                >
                <Typography variant="h6" gutterBottom>Entrar</Typography>
                <FormController //para Club
                    p_stt_FormSchema    = {stt_formLogin}
                    p_sstt_FormSchema   = {sstt_formLogin}
                    //p_stt_Opened_Dialogs  = {stt_FC_Opened_Dialogs}
                    //p_sstt_Opened_Dialogs = {sstt_FC_Opened_Dialogs}
                    pOnFormSubmitSuccess = {on_Login_Success}
                    />

            </Box>
        </main>
        )
    }


